import React from "react";

const buildSrcsetFromImages = (images) => {
  return [
    images.formats.large ? `${images.formats.large.url} ${images.formats.large.width}w` : null,
    images.formats.medium ? `${images.formats.medium.url} ${images.formats.medium.width}w` : null,
    images.formats.small ? `${images.formats.small.url} ${images.formats.small.width}w` : null,
    `${images.url} ${images.width}w`,
  ].filter(Boolean).join(", ");
}

const StereaImage = ({ images, sizes, alt, className }) => {
  return <img src={images.url} srcSet={buildSrcsetFromImages(images)} sizes={sizes} alt={alt} className={className} />
}

export default StereaImage